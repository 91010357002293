app.controller('HomeCtrl', ['$scope', '$window', '$filter', 'WorkService', function ($scope, $window, $filter, WorkService) {

  $scope.defaultTypes = ['website', 'specificApp', 'mobile'];
  $scope.selectedTypes = $scope.defaultTypes;

  $scope.projects = WorkService.list();

  $scope.buildCarousel = function () {
    
    var itemsPerScreen = getItemsPerScreen($window.innerWidth);

    var projects = [];
    for (var i = 0; i < $scope.projects.length; i++) {
      var selected = false;
      for (var j = 0; j < $scope.selectedTypes.length; j++) {
        if ($scope.projects[i].types.indexOf($scope.selectedTypes[j]) !== -1) {
          var selected = true;
        }
      }
      if (selected) {
        projects.push($scope.projects[i]);
      }
    }

    var projectScreens = [],
        slide = [];
    for (var i = 0; i < projects.length; i++) {
      if (slide.length === itemsPerScreen) {
        projectScreens.push(slide);
        slide = [];
      }
      slide.push(projects[i]);
    }
    projectScreens.push(slide);

    $scope.projectScreens = projectScreens;
  };

  angular.element($window).on('resize', function () {
    $scope.buildCarousel();
  });

  $scope.buildCarousel();

  function getItemsPerScreen(width) {
    if (width >= 768) {
      return 3;
    }
    return 1;
  }

}]);