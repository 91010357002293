var app = angular.module('buddyweb', [
  'ui.router',
  'ui.router.metatags',
  'uiGmapgoogle-maps',
  'angular-carousel',
  'ngAnimate',
  'pascalprecht.translate'
])

app.constant('API_BASE_URL', 'https://api.buddyweb.fr/')

app.config([
  '$stateProvider',
  '$locationProvider',
  'UIRouterMetatagsProvider',
  '$urlRouterProvider',
  'uiGmapGoogleMapApiProvider',
  function(
    $stateProvider,
    $locationProvider,
    UIRouterMetatagsProvider,
    $urlRouterProvider,
    uiGmapGoogleMapApiProvider
  ) {
    // remove URL hashbang
    $locationProvider.html5Mode(true).hashPrefix('!')

    $stateProvider
      // English
      .state('englishHome', {
        url: '/',
        views: {
          header: { templateUrl: 'templates/partials/header.html' },
          content: {
            templateUrl: 'templates/pages/home.html',
            controller: 'HomeCtrl'
          },
          footer: { templateUrl: 'templates/partials/footer.html' }
        },
        metaTags: {
          title: 'Web and Mobile Agency in Paris 100% Independent | Buddyweb ®',
          description:
            'Web and Mobile Agency in Paris since 2011. Digital projects, web apps, mobile apps, website development...'
        }
      })
      .state('englishAbout', {
        url: '/about',
        views: {
          header: { templateUrl: 'templates/partials/header.html' },
          content: { templateUrl: 'templates/pages/about.html' },
          footer: { templateUrl: 'templates/partials/footer.html' }
        },
        metaTags: {
          title: 'Discover our Agency | Buddyweb ®',
          description:
            'Discover Buddyweb Digital Agency based in Paris, France.'
        }
      })
      .state('englishWork', {
        url: '/work',
        views: {
          header: { templateUrl: 'templates/partials/header.html' },
          content: {
            templateUrl: 'templates/pages/work.html',
            controller: 'WorkCtrl'
          },
          footer: { templateUrl: 'templates/partials/footer.html' }
        },
        metaTags: {
          title: 'Work | Buddyweb ®',
          description:
            'Porfolio : Custom development, web and mobile apps and websites.'
        }
      })
      .state('englishContact', {
        url: '/contact',
        views: {
          header: { templateUrl: 'templates/partials/header.html' },
          content: {
            templateUrl: 'templates/pages/contact.html',
            controller: 'ContactCtrl'
          },
          footer: { templateUrl: 'templates/partials/footer.html' }
        },
        metaTags: {
          title: 'Contact | Buddyweb ®',
          description:
            'Contact Buddyweb Paris - A Digital Agency for Innovators - 171 quai de Valmy, 75010 Paris, France // 01 40 38 76 08'
        }
      })
      .state('englishPrivacyLegacy', {
        url: '/privacy-legacy',
        views: {
          header: { templateUrl: 'templates/partials/header.html' },
          content: { templateUrl: 'templates/pages/privacy-legacy.html' },
          footer: { templateUrl: 'templates/partials/footer.html' }
        },
        metaTags: {
          title: 'Privacy Legacy | Buddyweb ®',
          description: 'Privacy Legacy of Buddyweb website https://buddyweb.fr'
        }
      })
      .state('englishSupportAndHosting', {
        url: '/support-and-hosting',
        views: {
          header: { templateUrl: 'templates/partials/header.html' },
          content: { templateUrl: 'templates/pages/support-and-hosting.html' },
          footer: { templateUrl: 'templates/partials/footer.html' }
        },
        metaTags: {
          title: 'Support and Hosting | Buddyweb ®',
          description:
            'Discover our services of support and hosting for your web applications or websites.'
        }
      })
      .state('englishBlockchainServices', {
        url: '/blockchain-services',
        views: {
          header: { templateUrl: 'templates/partials/header.html' },
          content: { templateUrl: 'templates/pages/blockchain-services.html' },
          footer: { templateUrl: 'templates/partials/footer.html' }
        },
        metaTags: {
          title: 'Blockchain Consulting Services | Buddyweb ®',
          description:
            'Discover our blockchain services for decentralized applications and smart contract development.'
        }
      })
      .state('english404', {
        url: '/404',
        views: {
          header: { templateUrl: 'templates/partials/header.html' },
          content: { templateUrl: 'templates/errors/404.html' },
          footer: { templateUrl: 'templates/partials/footer.html' }
        },
        metaTags: {
          title: '404 Error | Buddyweb ®',
          description: '404 Error : Page not found.'
        }
      })

    // For any unmatched url, send to 404
    $urlRouterProvider.otherwise('404')

    uiGmapGoogleMapApiProvider.configure({
      key: 'AIzaSyCDo-0XTPLDviYQAlcioSGuyXe5VJ6R3JY',
      libraries: 'weather,geometry,visualization'
    })

    // Meta tags configuration
    // TODO : integrate FB and TW ids in that declaration
    UIRouterMetatagsProvider.setDefaultTitle('Buddyweb')
      .setDefaultDescription('Agence digitale pour les innovateurs')
      .setOGURL(true)
  }
])

// Meta Tags
function runBlock($rootScope, MetaTags) {
  $rootScope.MetaTags = MetaTags
}
app.run(['$rootScope', 'MetaTags', runBlock])

app.run([
  '$rootScope',
  '$stateParams',
  '$translate',
  function($rootScope, $stateParams, $translate) {
    $rootScope.$on('$stateChangeSuccess', function(event, toState) {
      $rootScope.toggle = 'body'

      document.body.scrollTop = document.documentElement.scrollTop = 0

      $rootScope.lang = 'en'
      $translate.use($rootScope.lang)
    })
  }
])

app.filter('onlyTypes', function() {
  return function(items, types) {
    return items.filter(function(item) {
      for (var i = 0; i < types.length; i++) {
        if (item.types.indexOf(types[i]) != -1) return true
      }
      return false
    })
  }
})

app.directive('fileread', [
  function() {
    return {
      scope: {
        fileread: '='
      },
      link: function(scope, element, attributes) {
        element.bind('change', function(changeEvent) {
          scope.$apply(function() {
            scope.fileread = changeEvent.target.files[0]
          })
        })
      }
    }
  }
])
