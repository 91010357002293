app.controller('ContactCtrl', ['$scope', '$http', 'API_BASE_URL', '$timeout', '$window', function ($scope, $http, API_BASE_URL, $timeout) {

  $scope.submitForm = function (isValid) {
    $scope.submitted = true;
    if (isValid) {
      $scope.loading = true;

      // build formData
      var formData = new FormData();
      if ($scope.lead.name) formData.append('name', $scope.lead.name);
      if ($scope.lead.company) formData.append('company', $scope.lead.company);
      if ($scope.lead.phone_number) formData.append('company', $scope.lead.phone_number);
      if ($scope.lead.email) formData.append('email', $scope.lead.email);
      if ($scope.lead.comment) formData.append('comment', $scope.lead.comment);
      if ($scope.lead.file) formData.append('file', $scope.lead.file);

      $http.post(API_BASE_URL + 'leads', formData, {
        transformRequest: angular.identity,
        headers: {'Content-Type': undefined}
      })
          .then(function (data, status, headers, config) {
            $scope.loading = false;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            $scope.lead = false;
            $scope.submitted = false;
            $scope.submitStatus = 'succeeded';
            // send analytics event

            $timeout(hideSumbitMessage, 6000);
          }, function (data, status, header, config) {
            $scope.loading = false;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            $scope.submitStatus = 'failed';
            $timeout(hideSumbitMessage, 6000);
          });
    }
  };

  function hideSumbitMessage() {
    return $scope.submitStatus = null;
  }

  $scope.map = {
    center: {latitude: 48.8795690, longitude: 2.3668960},
    zoom: 14
  };
  $scope.mapOptions = {
    scrollwheel: false,
    draggable: true,
    styles: [
      {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
          {
            "saturation": "-100"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.text",
        "stylers": [
          {
            "color": "#545454"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "saturation": "-87"
          },
          {
            "lightness": "-40"
          },
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#f0f0f0"
          },
          {
            "saturation": "-22"
          },
          {
            "lightness": "-16"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "saturation": "-52"
          },
          {
            "hue": "#00e4ff"
          },
          {
            "lightness": "-16"
          }
        ]
      }
    ]
  };
  $scope.marker = {
    id: 0,
    coords: {
      latitude: 48.8795690,
      longitude: 2.3668960
    },
    options: {
      icon: './dist/images/pointer.png',
    }
  }
}]);