app.config(['$translateProvider', function ($translateProvider) {

  $translateProvider.useSanitizeValueStrategy(null)
  $translateProvider.preferredLanguage('fr')

  $translateProvider.translations('fr', {
    header: {
      home: 'Accueil',
      projects: 'Projets',
      agency: 'Agence',
      contact: 'Contact'
    },
    footer: {
      privacyLegacy: 'Mentions légales'
    },
    home: {
      hero: {
        baseline: 'Agence Digitale<br>pour les innovateurs',
        subtitle: 'Buddyweb vous aide à créer les outils digitaux qui répondent aux challenges de votre activité.',
        link: 'Voir les projets'
      },
      skills: {
        title: 'Nos compétences',
        uxDesign: {
          title: 'UX Design',
          description: 'Nous imaginons des <strong>interfaces web et mobile</strong> élégantes et intuitives, centrées sur les utilisateurs.'
        },
        dev: {
          title: 'Développement Full-Stack',
          description: 'Solutions rapides et fiables grâce aux dernières technologies <strong>front-end et back-end</strong>.'
        },
        int: {
          title: 'Intégration graphique',
          description: 'Dans le plus pur respect de la <strong>vision artistique</strong> et du pragmatisme du développement.'
        }
      },
      supportAndHosting: {
        title: 'Support technique et hébergement',
        description: 'Obtenez un accompagnement durant toute la durée de vie de votre application.',
        anchor: 'En savoir plus'
      },
      blockchainServices: {
        title: 'Consulting Blockchain',
        description: 'Comprenez et utilisez la BlockChain dans les meilleures conditions',
        anchor: 'En savoir plus'
      },
      projects: {
        title: 'Projets',
        all: 'Tout',
        webApp: 'Application métier',
        mobileApp: 'Application mobile',
        website: 'Site web'
      },
      customers: {
        title: 'Nos clients',
        description: 'Buddyweb collabore avec des organisations et entreprises qui innovent et se démarquent dans leur activité.'
      },
      cta: {
        title: 'Vous avez un projet digital ?',
        description1: 'Buddyweb, Agence web à Paris, a l\'expérience pour vous accompagner à réaliser votre projet de transformation digitale, de développement d\'applications métier (web et mobile) et de création de site web.',
        description2: 'Nous pouvons nous intégrer à votre équipe ou bien travailler en parallèle..',
        link: 'Contactez-nous'
      },
      hack: {
        title: 'Laissez-nous hacker votre futur',
        description: '<p><strong>Innover</strong> en sortant des sentiers battus impose une approche précise : forte réactivité, itérations fréquentes et mentalité "startup". </p> <p>Notre équipe a développé une expertise dans la réalisation de projets innovants et dans la <strong>digitalisation</strong> de l\'existant. </p> <p>Nous sommes prêts à démarrer et à partir pour l\'inconnu. Et vous ?</p>',
        link: 'Découvrez Buddyweb'
      },
      technology: {
        title: 'Nous aimons travailler avec ces technos',
        description: 'Pour vous servir ce qui se fait de mieux, notre agence web se forme continuellement dans les dernières technologies disponibles.'
      }
    },
    agency: {
      title: 'Agence Digitale<br>pour les innovateurs',
      subtitle: 'Buddyweb vous aide à créer les outils digitaux qui répondent aux challenges de votre activité.',
      team: {
        title: 'Une équipe passionnée',
        sebastien: {
          fullName: 'Sébastien Conejo',
          job: 'Directeur artistique',
          bio: '<p>Aucun détail ne lui échappe. Sébastien a une vision pratique instantanée de tout ce qu\'il regarde. </p> <p>Créatif par profession, créatif à ses heures perdues, il écoute les utilisateurs car il sait qu\'ils détiennent la connaissance qu\'il cherche : la connaissance de l\'usage, qu\'ilvient compléter avec son expérience et son style efficace et délicat. </p> <p>Malgré les convoitises, il demeure à ce jour invaincu à Street Fighter. </p>'
        },
        bruno: {
          fullName: 'Bruno Pérez',
          job: 'Développeur full-stack',
          bio: '<p>Geek et bidouilleur dans l\'âme, Bruno arrive toujours avec une nouvelle idée de projet à entreprendre.</p>  <p>Chez Buddyweb il architecture et construit des solutions techniques efficaces. Il croit au beau code : celui qui combine le style, l\'éloquence et la performance.  </p>  <p>Il est passionné par la blockchain, l\'open-source et la culture "Maker".  </p>'
        },
        julien: {
          fullName: 'Julien Fernandez',
          job: 'Digital strategist',
          bio: '<p>Fin stratège, veilleur et digital-hispter, Julien est toujours informé de la dernière tendance du moment. Son expérience dans différents postes du digital lui ont forgé la compréhension solide qu\'il a du secteur.  </p>  <p>Entre les cours de marketing qu\'il donne à ses élèves, il améliore la communication et la visibilité des clients chez Buddyweb.  </p>  <p>Il s\'interesse particulièrement au storytelling et aux nouvelles possibilités qu\'apportent les médias digitaux.</p>'
        }
      },
      advantages: {
        title: 'Vous pouvez compter sur Buddyweb',
        expertise: {
          title: 'Expertise',
          description: 'La solidité d\'une équipe avec une expérience de plus de 150 projets digitaux réalisés avec succès.'
        },
        accompaniment: {
          title: 'Accompagnement',
          description: 'Discutons de professionnel à professionnel. Un conseil et un suivi de qualité tout au long du projet.'
        },
        integrity: {
          title: 'Intégrité',
          description: 'Nous cherchons le plus simple et le plus efficace. Transparence totale sur les moyens et les résultats attendus.'
        }
      },
      about: {
        title: 'Une agence avec du caractère',
        openSource: {
          title: 'Open source',
          description: '<p>Quels sont les avantages d\'un produit basé sur une les technologie open-source ?</p><p>Il est performant, fiable et on peut facilement le maintenir et le faire évoluer. Contrairement à solution basée sur une technologie propriétaire, il vous appartient totalement : vous pouvez en disposer comme vous voulez.  </p>  <p>Chez Buddyweb nous croyons en ces concepts. L\'équipe se forme constamment sur les nouvelles mises à jour et nous sommes à l\'affut des dernières technologies sur le marché.</p>'
        },
        experience: {
          title: 'Une agence créatrice d\'expérience',
          description: '<p>L\'époque où chaque produit venait avec sa notice d\'utilisation est révolue. Les utilisateurs doivent bénéficier de la meilleure expérience possible. Notre réflexion s\'accentue donc sur l\'utilisation : analyser les besoins et les comportements des utilisateurs dans des conditions réelles, et agir en conséquence.</p>  <p>La création visuelle vient sublimer le tout. Rien n\'est laissé au hasard : espacements, couleurs, typographie. Une harmonie qui a pour but d\'impacter émotionnellement les utilisateurs.</p>',
          link: 'Voir nos réalisations'
        },
        passion: {
          title: 'Une agence passionnée',
          description: '<p>Nous nous levons le matin car nous sommes passionnés par notre travail. Nous créons, nous codons, nous apprenons et prenons plaisir à le faire.</p>  <p>Participants (et parfois gagnants) de hackathons, retro-gamers, side-project addicts, adeptes de l\'open-source, nous sommes surtout enthousiastes de ce que le futur nous réserve. Utilisons la technologie pour créer un monde meilleur !</p>'
        }
      },
      cta: {
        title: 'Vous avez un projet digital ?',
        link: 'Contactez-nous'
      }
    },
    contact: {
      title: 'Contact',
      subtitle: 'Dites nous quelques mots sur vous et votre projet et mettons-nous au travail !',
      errorMessage: 'Votre message n\'a pas pu être envoyé pour une raison mystérieuse... Vous pouvez toujours nous contacter par d\'autres moyens !',
      successMessage: 'Votre message a bien été envoyé ! L\'équipe Buddyweb vous contactera sous peu.',
      form: {
        name: 'Nom et Prénom *',
        company: 'Entreprise *',
        phone: 'Téléphone',
        email: 'Email',
        file: 'Ajoutez votre cahier des charges',
        comment: 'Quelques mots sur votre projet...',
        submit: 'Envoyer'
      }
    },
    supportAndHosting: {
      title: 'Support Technique et Hébergement',
      support: {
        title: 'Support technique',
        subtitle: 'Intégrez l\'expertise Buddyweb dans votre é́quipe pour maintenir et faire évoluer vos outils digitaux.',
        correctiveMaintenanceTitle: 'Maintenance corrective',
        correctiveMaintenanceContent: '<p>Correction réactive de bugs et de dysfonctionnements.</p>',
        bugTrackingTitle: 'Logiciel de bug tracking',
        bugTrackingContent: '  <ul> <li>Système de <strong>tickets</strong> pour échanger sur les différentes demandes </li> <li>Visualisation du progrès des demandes <strong>en temps réel</strong> </li> </ul>',
        stagingTitle: 'Environnements de pré-production',
        stagingContent: '<ul> <li>Distributions privées de <strong>pré-production</strong> de l\'application ou du site </li> <li>Utile pour validations, tests, démonstrations et formations</li> </ul>',
        estimateTitle: 'Estimation radide des demandes',
        estimateContent: '<strong>Contact simplifié</strong> pour les estimations de faisabilité et financières.',
        packageTitle: 'Forfaits de prestations techniques',
        packageContent: 'Différents <strong>forfaits de prestation</strong> pour travaux rapides sur l\'application: ajout de contenu, médias, petites évolutions...',
        phoneSupportTitle: 'Support téléphonique',
        phoneSupportContent: 'Un expert disponible pour vous conseiller, répondre à vos questions et <strong>avancer avec vous</strong>.',
        cta1: 'Vous avez des questions sur le support ?',
        cta2: 'Parlez immédiatement avec notre équipe.',
        contactLink: 'Contactez-nous'
      },
      hosting: {
        title: 'Hébergement',
        subtitle: 'Buddyweb vous propose des solutions cloud simples et efficaces.',
        readyTitle: 'Ready to use',
        readyContent: '<ul> <li>Configuration du serveur</li> <li>Installation de votre solution</li> <li>Déploiements automatisés</li> </ul>',
        backupTitle: 'Copies de sauvegarde',
        backupContent: '<ul>  <li>Copies de sauvegardes régulières du <strong>code-source et des données</strong>  </li>  <li>Possibilité de restaurer une ancienne version</li>  </ul>',
        httpsTitle: 'Connexion sécurisée HTTPS',
        httpsContent: '<p>Création et mise en place de <strong>certificats SSL</strong> pour proposer un accès sécurisé "https" à vos visiteurs.</p>  <p>Authentification par clé SSH et <strong>Firewall</strong> configuré par défaut.  </p>',
        availabilityTitle: 'Disponibilite',
        availabilityContent: '<p>Buddyweb a choisi Digital Ocean comme prestataire de qualité, qui garantit une disponibilité de <strong>99,99%.</strong></p>',
        scalableTitle: 'Redimensionable sans frais',
        scalableContent: '<p>Possibilité de redimentionner votre serveur pour améliorer les performances <strong>sans aucun frais d\'installation</strong>.</p>',
        performanceTitle: 'Performance',
        performanceContent: 'Nos serveurs Ubuntu sont compatibles avec des <strong>technologies reconnues pour leur performance</strong> comme NodeJS ou PHP 7.'
      }
    },
    blockchainServices: {
      title: 'Consulting blockchain',
      subtitle: 'Construisez aujourd\'hui avec la technologie de demain.',
      developmentTitle: 'Développement Blockchain',
      developmentContent: 'Développement d\'applications décentralisées (DAPP) et déploiement sur des infrastructures globales et inaltérables : contrats digitaux, gouvernance, propriété, traçabilité, finance...',
      ethereumTitle: 'Environnement Ethereum',
      ethereumContent: 'Spécialistes de la blockchain Ethereum (2ème plus important marché après Bitcoin) et de son environnement : Solidity, Truffle, Web3...',
      smartContractTitle: 'Conception de Smart Contracts',
      smartContractContent: 'Architecture et développement de smart contracts : des applications qui fonctionnement sur la blockchain et qui ne peuvent jamais être arrêtées, corrompues ou modifiées.',
      interfaceTitle: 'Interfaces Web3',
      interfaceContent: 'Pour que les utilisateurs puissent interagir avec les applications blockchains, il leur faut une interface. Nous développons des interfaces ergonomiques, web ou applications desktop, capables de dialoguer avec des composants blockchain.',
      trainingTitle: 'Blockchain training',
      trainingContent: 'Beaucoup de corps de métiers peuvent être disruptés par la blockchain. A ce titre il important d\'en saisir les concepts et les enjeux, ce sur quoi portent nos formations.',
      consultingTitle: 'Consulting blockchain',
      consultingContent: 'Des experts blockchains disponibles pour vous accompagner dans votre projet blockchain.',
      cta1: 'Une question ?',
      cta2: 'Nous sommes là pour vous répondre sur les problématiques blockchain.',
      contactLink: 'Contactez notre équipe'
    }
  })

  $translateProvider.translations('en', {
    header: {
      home: 'Home',
      projects: 'Projects',
      agency: 'Agency',
      contact: 'Contact'
    },
    footer: {
      privacyLegacy: 'Privacy legacy'
    },
    home: {
      hero: {
        baseline: 'A Digital Agency<br>for innovators',
        subtitle: 'Buddyweb is here to help you create the appropriate digital tools you need.',
        link: 'Discover our projects'
      },
      skills: {
        title: 'Our skills',
        uxDesign: {
          title: 'UX Design',
          description: 'We imagine, user-centered <strong>web and mobile interfaces </strong> that are elegant and intuitive.'
        },
        dev: {
          title: 'Full-Stack development',
          description: 'Fast and reliable solutions using the latest <strong>front-end and back-end</strong> technologies.'
        },
        int: {
          title: 'Graphic integration',
          description: 'We integrate graphics in the purest <strong> artistic vision </strong> and development pragmatism.'
        }
      },
      supportAndHosting: {
        title: 'Technical support and hosting',
        description: 'Technical services during all the lifetime of your application.',
        anchor: 'Read more'
      },
      blockchainServices: {
        title: 'Blockchain Consulting',
        description: 'Understand and use the BlockChain in the best conditions.',
        anchor: 'Read more'
      },
      projects: {
        title: 'Projects',
        all: 'All',
        webApp: 'Custom application',
        mobileApp: 'Mobile application',
        website: 'Website'
      },
      customers: {
        title: 'Our customers',
        description: 'Buddyweb collaborate with innovative organizations and companies that stand out in their field.'
      },
      cta: {
        title: 'Do you have a digital project ?',
        description1: 'Buddyweb, Web Agency in Paris, has the necessary experience to support you in achieving your digital transformation projects, custom application development (web and mobile) and website creation.',
        description2: 'We can either integrate your team or work as your provider.',
        link: 'Contact us'
      },
      hack: {
        title: 'Let us hack your future',
        description: '<p>To be able to <strong>innovate</strong> outside the beaten track, one needs to impose a precised approach: strong reactivity, frequent iterations and a "startup" state of mind.</p> <p>Our team has developed an expertise in the realization of innovating projects and in the digitizing aging and inefficient workflows.</p> <p>We are ready to start and go to the future. And you ?</p>',
        link: 'Discover Buddyweb'
      },
      technology: {
        title: 'We love to work with the following technologies',
        description: 'To get you what is best out there, our agency gets trained on the latest available technologies.'
      }
    },
    agency: {
      title: 'A digital agency <br>for innovators',
      subtitle: 'To respond to your activity challenges, Buddyweb is here to help you create the appropriate digital tools you need.',
      team: {
        title: 'A passionate team',
        sebastien: {
          fullName: 'Sébastien Conejo',
          job: 'Artistic director',
          bio: '<p>He captures all the details.  Sebastien has an instant practical vision of everything he looks at.</p> <p>Creative by profession, creative in his spare time, he knows how to listen to the users, because they hold the knowledge he is looking for: the knowledge of usage. He then completes this knowledge with his experience and his efficient, yet delicate style.</p> <p>Even though Sebastien is a busy geek, he is, to this day, undefeated at Street Fighter.</p>'
        },
        bruno: {
          fullName: 'Bruno Pérez',
          job: 'Full-stack developer',
          bio: '<p>Geek and hacker, Bruno, always has a new project idea to undertake.</p><p>At Buddyweb he is the architect and the builder of efficient technical solutions. He believes in the beauty in coding, the kind of code which combines style, eloquence and performance.</p><p>He is passionate about Blockchain technology, open-source and the "maker" culture.</p>'
        },
        julien: {
          fullName: 'Julien Fernandez',
          job: 'Digital strategist',
          bio: '<p>Fine strategist and digital-hispter, Julien is always aware about the lasted technology in the market. His experience in several positions of the digital area gave him his solid knowledge.</p><p>When he is not giving marketing classes to his students, he advices and enhances visibility of digital platforms.</p>  <p>He is interested in the power of storytelling and social media.</p>'
        }
      },
      advantages: {
        title: 'You can count on Buddyweb',
        expertise: {
          title: 'Expertise',
          description: 'The strength of a team with an experience of more than 150 digital projects achieved with success.'
        },
        accompaniment: {
          title: 'Support',
          description: 'Let\'s talk between professionals. Quality support and consulting along the project.'
        },
        integrity: {
          title: 'Integrity',
          description: 'We are looking for the simplest and the most efficient. Total transparency on the means and expected results.'
        }
      },
      about: {
        title: 'An agency with character',
        openSource: {
          title: 'Open source',
          description: '<p>What are the advantages of a product based on open-source technology ?</p><p>It is powerful, reliable and maintainable. It is all yours : you may dispose of it as you wish. The opposite of a solution based on proprietary technology.</p>  <p>At Buddyweb we believe in open source. The team gets constant training on new updates and we are always on the look out for the latest technologies on the market. </p>'
        },
        experience: {
          title: 'An agency that creates experiences',
          description: '<p>The time where every product came with a notice is now over. The users deserve the best experience possible. Our reflection therefore focuses on usage : understanding the needs and the behaviors of users in real conditions and act upon them. </p>  <p>The visual creation comes and sublimes the whole.  Nothing is left to chance : spacing, colors, typography. A harmony made to emotionally impact users.</p>',
          link: 'See our portfolio'
        },
        passion: {
          title: 'A passionate agency',
          description: '<p>We wake up each morning because we are passionate about our work. We create, we code, we learn and we take pleasure doing so.</p>  <p>Participants (and sometime winners) of hackathons, retro-gamers, side-project addicts, open source believers, we are foremost enthusiast for what the future holds. Let’s use technology to create a better world! </p>'
        }
      },
      cta: {
        title: 'Do you have digital project ?',
        link: 'Contact us'
      }
    },
    contact: {
      title: 'Contact',
      subtitle: 'Tell us more about you and your project and will get to work !',
      errorMessage: 'Sorry, we couldn\'t send your message for some reason ! Please contact us by phone or email.',
      successMessage: 'Your message has been successfully sent ! We will come back to you soon.',
      form: {
        name: 'Full name *',
        company: 'Company *',
        phone: 'Phone',
        email: 'E-mail',
        file: 'Join your functional specification',
        comment: 'A few words about your project...',
        submit: 'Send'
      }
    },
    supportAndHosting: {
      title: 'Support and Hosting',
      support: {
        title: 'Technical Support',
        subtitle: 'Integrate Buddyweb\'s expertise in your own team to maintain and upgrade your digital assets.',
        correctiveMaintenanceTitle: 'Corrective maintenance',
        correctiveMaintenanceContent: '<p>Corrective maintenance, reactive bug fixing and quick deploys.</p>',
        bugTrackingTitle: 'Bugtracking software',
        bugTrackingContent: '  <ul> <li><strong>Ticketing system</strong> to manage requests </li> <li>Monitoring progress of your requests <strong>in real time</strong> </li> </ul>',
        stagingTitle: 'Staging environments',
        stagingContent: '<ul> <li>Private and <strong>staging</strong> distributions for your app or website </li> <li>Useful for validation, tests, demos and training</li> </ul>',
        estimateTitle: 'Rapid estimation of requests',
        estimateContent: '<strong>Simplified contact</strong> for feasibility and financial estimates.',
        packageTitle: 'Packages of technical services',
        packageContent: 'Choose your <strong>technical package</strong> for quick work on the application : adding content, media, small changes ..',
        phoneSupportTitle: 'Phone support',
        phoneSupportContent: 'An expert available to advise you, answer your questions and <strong>help you</strong>.',
        cta1: 'Do you want to learn more about support services ?',
        cta2: 'Talk with our team now.',
        contactLink: 'Contact us'
      },
      hosting: {
        title: 'Hosting',
        subtitle: 'Simple and efficient cloud-based hosting solutions by Buddyweb.',
        readyTitle: 'Ready to use',
        readyContent: '<ul> <li>Server configuration</li> <li>Installing your solution</li> <li>Automatic deploys</li> </ul>',
        backupTitle: 'Backups',
        backupContent: '<ul>  <li>Regular backup copies of <strong>source code and database</strong>  </li>  <li>Ability to restore an old version</li>  </ul>',
        httpsTitle: 'HTTPS secure connection',
        httpsContent: '<p>Generation and implementation of <strong>SSL certificates</strong> for encrypted <strong>https</strong> access.</p>  <p>Authentication by SSH key and <strong>Firewall</strong> configured out of the box.</p>',
        availabilityTitle: 'Availability',
        availabilityContent: '<p>Buddyweb chose Digital Ocean for reliable hosting, with an availability of <strong>99,99%.</strong></p>',
        scalableTitle: 'Scalability',
        scalableContent: '<p>Easily scalable to improve storage and performance as you application grows,  <strong>without installation fees</strong>.</p>',
        performanceTitle: 'Performance',
        performanceContent: 'Our Ubuntu servers are suited for <strong>technologies recognized for their performance</strong> like NodeJS or PHP 7.'
      }
    },
    blockchainServices: {
      title: 'Consulting blockchain',
      subtitle: 'Start building today applications with tomorrow\'s technology',
      developmentTitle: 'Blockchain development',
      developmentContent: 'Decentralized applications (DAPP) development and deployments on global and unalterable networks : smart contracts, governance, property, traceability, finance...',
      ethereumTitle: 'Ethereum environment',
      ethereumContent: 'Ethereum blockchain specialists (2nd largest market cap after Bitcoin) and its environment : Solidity, Truffle, Web3...',
      smartContractTitle: 'Smart contract development',
      smartContractContent: 'Development and software architecture for smart contracts : apps that run on the blockchain and cannot be stopped, changed or corrupted.',
      interfaceTitle: 'Web3 interfaces',
      interfaceContent: 'In order for users to interact with blockchains applications, we need an interface. We create ergonomic interfaces, web or desktop applications, that can connect with blockchain components',
      trainingTitle: 'Blockchain training',
      trainingContent: 'Blockchain disruptive power is huge and concern many areas and jobs. For this reason it\'s important to get general concepts and issues.',
      consultingTitle: 'Blockchain consulting',
      consultingContent: 'Blockchain experts to help you develop your blockchain project. ',
      cta1: 'Have a question ?',
      cta2: 'We are available to answer to you questions about blockchain.',
      contactLink: 'Contact our team'
    }
  })
}])
