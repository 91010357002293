app.service('WorkService', ['$sce', function ($sce) {
  // types : "specificApp", 'mobile" or 'website

  this.list = function () {
    return [

      {
        name: 'Galeries Lafayette',
        description: 'Jeu concours',
        types: ['mobile', 'website'],
        class: 'card--work--3',
        link: 'workLafayette',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-darkness"><div class="absolute layer-center  layer-center--mobile"> <img src="./dist/images/project/lafayette/lafayette-cover.png" alt="Galeries Lafayette l\'académie | Passeport digital" class="layer2"> </div> <div class="absolute layer-back-left  layer-back-left--mobile  bg-light"> <img src="./dist/images/project/lafayette/lafayette-2.png" alt="Site web pour les Galeries Lafayette | Passeport digital" class="layer2"> </div> <div class="absolute layer-back-right  bg-light  layer-back-right--mobile"> <img src="./dist/images/project/cora/lafayette-1.png" alt="Sit eweb pour Galeries Lafayette | Passeport digital" class="layer2"> </div></div>')
      },
      {
        name: 'Enedis',
        description: 'Réalisation UX/UI Application Galeries Parisiennes',
        types: ['specificApp'],
        class: 'card--work--1',
        link: 'workEnedisGaleries',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-mint"><img src="./dist/images/project/enedis-galeries/enedis-galeries-cover.png" alt="Application métier sur-mesure pour Enedis"> </div>')
      },
      {
        name: 'Cora',
        description: 'Digitalisation de la prise de commandes en magasin',
        types: ['mobile', 'specificApp'],
        class: 'card--work--3',
        link: 'workCora',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-positive"><div class="absolute layer-center  layer-center--mobile"> <img src="./dist/images/project/cora/cora-fg.png"srcset="./dist/images/project/cora/cora-fg.png 1x"sizes="(min-width: 767px) 49vw, 12.2vw" alt="Application mobile de prise de commande" class="layer2"> </div> <div class="absolute layer-back-left  layer-back-left--mobile  bg-light"> <img src="./dist/images/project/cora/cora-back1.svg"srcset="./dist/images/project/cora/cora-back1.svg" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Application Cora LDD permettant de passer une commande de produits directement en magasin via un smartphone" class="layer2"> </div> <div class="absolute layer-back-right  bg-light  layer-back-right--mobile"> <img src="./dist/images/project/cora/cora-back2.svg" srcset="./dist/images/project/cora/cora-back2.svg" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Application de prise de commande dématéralisée" class="layer2"> </div></div>')
      },
      {
        name: 'Nexity',
        description: 'Développement du Campus e-learning',
        types: ['specificApp', 'mobile'],
        class: 'card--work--2',
        link: 'workNexity',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-cardinal"> <img src="./dist/images/thumb-nexity-bg.png"srcset="./dist/images/thumb-nexity-bg-xs.png 1x,./dist/images/thumb-nexity-bg.png 1.25x" sizes="(min-width: 767px) 90vw, 33vw" alt="E-learning web application pour Nexity" class="layer1"> <div class="absolute device-mobile"> <img src="./dist/images/nexity-mobile.png"srcset="./dist/images/nexity-mobile.png 1x,./dist/images/nexity-mobile-xxl.png 2x"sizes="(min-width: 767px) 49vw, 12.2vw" alt="E-learning mobile pour Nexity" class="layer2"> </div> </div>')
      },
      {
          name: 'Feets-FO',
          description: 'Réalisation d\'une plateforme multisite personnalisable',
          types: ['specificApp', 'website','mobile'],
          class: 'card--work--1 card--work--feets-fo',
          link: 'workFeetsFo',
          template: $sce.trustAsHtml('<div class="card--work__thumb bg-feets"><img src="./dist/images/project/feets-fo/first-screen-accueil.jpg" alt="Plateforme mulitsite : Réalisation d\'une plateforme personnalisable pour FEETS-FO"> </div>')
      },
      {
          name: 'Bridge',
          description: 'Conception d\'un outil de création d\'APIs en ligne',
          types: ['specificApp'],
          class: 'card--work--1',
          link: 'workBridge',
          template: $sce.trustAsHtml('<div class="card--work__thumb bg-bridge"><img src="./dist/images/project/bridge/exemple-doc-l.png" alt="Application Bridge : Docuemntation générée à partir d\'un fichier EXCEL"> </div>')
      },
      {
        name: 'Orchestre de l\'Opéra de Massy',
        description: 'Développement du site web',
        types: ['website'],
        class: 'card--work--2',
        link: 'workOrchestre',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-candy"><img src="./dist/images/bg-orchestre-desktop.jpg" srcset="./dist/images/bg-orchestre-desktop.jpg 1x,./dist/images/bg-orchestre-desktop.jpg 1.25x" sizes="(min-width: 767px) 90vw, 33vw" alt="Site web de l\'Orchestre de l\'Opéra de Massy" class="layer1"> <div class="absolute device-mobile"><img src="./dist/images/bg-orchestre-mobile.png" srcset="./dist/images/bg-orchestre-mobile.png 1x,./dist/images/bg-orchestre-mobile.png 2x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Maquette graphique pour l\'Orchestre de l\'Opéra de Massy" class="layer2"></div> </div>')
      },
      {
        name: 'Enedis',
        description: 'Conception Application sécurité',
        types: ['specificApp'],
        class: 'card--work--1',
        link: 'workEnedis',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-electricity"><img src="./dist/images/dashboard-enedis.svg" alt="Application métier sur-mesure pour Enedis"> </div>')
      },
      {
        name: 'Spoilme',
        description: 'Application web et Android',
        types: ['mobile'],
        class: 'card--work--3',
        link: 'workSpoilme',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-spoil"><div class="absolute layer-center  layer-center--mobile"> <img src="./dist/images/spoilme-fg.png"srcset="./dist/images/spoilme-fg.png 1x"sizes="(min-width: 767px) 49vw, 12.2vw" alt="Application web et Android collaborative" class="layer2"> </div> <div class="absolute layer-back-left  layer-back-left--mobile"> <img src="./dist/images/spoilme-back1.png"srcset="./dist/images/spoilme-back1.png 1x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Application collaborative de Spoils" class="layer2"> </div> <div class="absolute layer-back-right  layer-back-right--mobile"> <img src="./dist/images/spoilme-back2.png" srcset="./dist/images/spoilme-back2.png 1x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Application mobile crowd-sourcing" class="layer2"> </div></div>')
      },
      {
        name: 'Afges',
        description: 'Développement plate-forme formations',
        types: ['website', 'specificApp'],
        class: 'card--work--3 card--work--afges',
        link: 'workAfges',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-piscine"> <div class="absolute layer-center"><img src="./dist/images/project/afges/afges-home.jpg" srcset="./dist/images/project/afges/afges-home.jpg 1x,./dist/images/project/afges/afges-home.jpg 2x,./dist/images/project/afges/afges-home.jpg 1.25x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="E-catalogue de formations Afges" class="layer2"></div> <div class="absolute layer-back-left"><img src="./dist/images/project/afges/afges-formations.jpg" srcset="./dist/images/project/afges/afges-formations.jpg 1x,./dist/images/project/afges/afges-formations.jpg 2x,./dist/images/project/afges/afges-formations.jpg 1.25x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Plate-forme formations Wordpress Afges" class="layer2"></div> <div class="absolute layer-back-right"><img src="./dist/images/project/afges/afges-formation.jpg" srcset="./dist/images/project/afges/afges-formation.jpg 1x,./dist/images/project/afges/afges-formation.jpg 2x,./dist/images/project/afges/afges-formation.jpg 1.25x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="E-catalogue de formations Afges" class="layer2"></div> </div>')
      },
      {
        name: 'SuprGuide',
        description: 'Développement guide touristique',
        types: ['website'],
        class: 'card--work--2  card--work--suprguide',
        link: 'workSuprguide',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-chemical"> <img src="./dist/images/project/suprguide/thumb-suprguide-bg.jpg"srcset="./dist/images/project/suprguide/thumb-suprguide-bg-xs.jpg 1x,./dist/images/project/suprguide/thumb-suprguide-bg.jpg 1.25x"sizes="(min-width: 767px) 90vw, 33vw"alt="Guide touristique en ligne Suprguide"class="layer1"> <div class="absolute device-mobile"> <img src="./dist/images/project/suprguide/suprguide-mobile.jpg"srcset="./dist/images/project/suprguide/suprguide-mobile.jpg 1x,./dist/images/project/suprguide/suprguide-mobile-xxl.jpg 2x"sizes="(min-width: 767px) 49vw, 12.2vw"alt="Guide touristique en ligne Suprguide"class="layer2"> </div> </div>')
      },
      {
        name: 'EasyLan',
        description: 'Création site web',
        types: ['website'],
        class: 'card--work--3  card--work--easylan',
        link: 'workEasyLan',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-calm"> <div class="absolute layer-center  layer-center--laptop"> <img src="./dist/images/project/easylan/easylan-homepage.png"srcset="./dist/images/project/easylan/easylan-homepage.png 1x,./dist/images/project/easylan/easylan-homepage.png 2x,./dist/images/project/easylan/easylan-homepage.png 1.25x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Création du site web de EasyLan" class="layer2"> </div> <div class="absolute layer-bottom"> <img src="./dist/images/project/easylan/easylan-security.png" srcset="./dist/images/project/easylan/easylan-security.png 1x,./dist/images/project/easylan/easylan-security.png 2x,./dist/images/project/easylan/easylan-security.png 1.25x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Homepage du site web de EasyLan" class="layer2"> </div> </div>')
      },
      {
        name: 'BMC',
        description: 'Création site web associatif',
        types: ['website'],
        class: 'card--work--3  card--work--bmc',
        link: 'workBMC',
        template: $sce.trustAsHtml('<div class="card--work__thumb bg-thinking"> <div class="absolute layer-center  layer-center--laptop"> <img src="./dist/images/project/bmc/bmc-homepage.jpg" srcset="./dist/images/project/bmc/bmc-homepage.jpg 1x,./dist/images/project/bmc/bmc-homepage.jpg 2x,./dist/images/project/bmc/bmc-homepage.jpg 1.25x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Conception Site web de BMC" class="layer2"> </div> <div class="absolute layer-bottom"> <img src="./dist/images/project/bmc/bmc-projets.jpg" srcset="./dist/images/project/bmc/bmc-projets.jpg 1x,./dist/images/project/bmc/bmc-projets.jpg 2x,./dist/images/project/bmc/bmc-projets.jpg 1.25x" sizes="(min-width: 767px) 49vw, 12.2vw" alt="Homepage du site web de BMC" class="layer2"> </div> </div>')
      },

    ];
  };

}]);
